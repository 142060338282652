import {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Pagination,
    PaginationProps,
    Row,
    Select,
    Space,
    Table,
    Tag
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    PhoneOutlined,
    PlusCircleOutlined,
    RedoOutlined,
    ReloadOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import type {ColumnsType} from "antd/es/table";
import type {CheckboxValueType} from "antd/es/checkbox/Group";
import {AccountData} from "../../domain/account";
import {accounts, listProjects} from "../../http";
import {changePassword, createAccount, deleteAccount, updateAccount} from "../../http/account";
import {ProjectData} from "../../domain";

const CryptoJS = require("crypto-js");

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 24},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function AcManagementPage() {
    const [dataForm] = Form.useForm();
    const [password_form] = Form.useForm();

    //multilanguage
    const {t} = useTranslation();

    //modal
    const [getModal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    //search
    const [nameValue, setNameValue] = useState("");
    const [phoneValue, setPhoneValue] = useState("");
    const [permissionValue, setPermissionValue] = useState(null);
    const [projectValue, setProjectValue] = useState(null);

    //get functionality list
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();

    //message
    const [messageApi, contextHolder] = message.useMessage();

    const permission = [
        {label: t("acManagement.userInfo"), value: "user.view"},
        {label: t("acManagement.acManagement"), value: "ac.view"},
        {label: t("acManagement.gateInfoAdmin"), value: "gateAdmin.view"},
        {
            label: t("acManagement.gateInfoTechnician"),
            value: "gateTechnician.view",
        },
        {label: t("acManagement.blockInfo"), value: "block.view"},
        {label: t("acManagement.cardInfo"), value: "card.view"},
        {label: t("acManagement.unlockLog"), value: "unlock.view"},
        {label: t("acManagement.zoneInfo"), value: "zone.view"},
    ];

    const [accountList, setAccountList] = useState([] as Array<AccountData>);
    const [projectList, setProjectList] = useState([] as Array<ProjectData>);

    const [accountQueryForm, setAccountQueryForm] = useState({
        page: 1,
        size: 5,
        total: 0,
        name: null,
        mobile: null,
        permission: null,
        project: null
    });

    const loadAccounts = (page = accountQueryForm.page,
                          size = accountQueryForm.size,
                          name = accountQueryForm.name,
                          mobile = accountQueryForm.mobile,
                          permission = accountQueryForm.permission,
                          project = accountQueryForm.project) => {
        let query = {
            page: page,
            size: size,
            name: name,
            mobile: mobile,
            permission: permission,
            projectId: project
        }
        accounts(query).then(result => {
            setAccountQueryForm((prevPagination) => ({
                ...prevPagination,
                total: result.totalElements // Update total count if available from backend
            }));
            setAccountList(result.content as any);
        })
    }
    const loadProjects = () => {
        listProjects().then(result => setProjectList((result as any).reverse()))
    }

    useEffect(() => {
        loadAccounts()
        loadProjects()
    }, []);

    const showModal = (text: any, data: any) => {
        setSelectedId(data.id);

        if (text === "edit") {
            dataForm.setFieldValue("name", data.name);
            dataForm.setFieldValue("phone", data.phone);
            setCheckedList(JSON.parse(data.permission));
            dataForm.setFieldValue("project", JSON.parse(data.project));
            setModal(true);
        } else if (text === "delete") {
            setDeleteModal(true);
        } else if (text === "add") {
            setModal(true);
        } else if (text === "password") {
            setChangePasswordModal(true);
        }
    };

    const onChangeCheckBox = (checkedValues: CheckboxValueType[]) => {
        setCheckedList(checkedValues);
    };

    const generateRandomString = () => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    };

    const handleSubmit = (values: any) => {
        const salt = generateRandomString();
        const hashedPassword = CryptoJS.MD5(values.password + salt).toString();

        let data = {
            name: values.name,
            phone: values.phone,
            password: hashedPassword,
            salt: salt,
            project:
                JSON.stringify(values.project) === undefined
                    ? "[]"
                    : JSON.stringify(values.project),
            permission: JSON.stringify(checkedList),
        };

        const action = selectedId ? updateAccount(selectedId, data) : createAccount(data)
        action.then(r => {
            messageApi.open({
                type: "success",
                content: t(selectedId ? "acManagement.msg.edit" : "acManagement.msg.add"),
            }).then(r => {
            });
            setModal(false);
            dataForm.resetFields();
            loadAccounts()
        }).catch(() => {
            messageApi.open({
                type: "error",
                content: t("general.somethingWentWrong"),
            }).then()
        })
    };

    const handleSubmitChangePassword = (values: any) => {
        const salt = generateRandomString();
        const hashedPassword = CryptoJS.MD5(values.password + salt).toString();

        let data = {
            password: hashedPassword,
            salt: salt,
        };

        changePassword(selectedId, data).then(r => {
            messageApi.open({
                type: "success",
                content: t("acManagement.messageSuccessPasswordChanged"),
            }).then(r => {
            });
            setChangePasswordModal(false);
            password_form.resetFields();
        }).catch(() => {
            messageApi.open({
                type: "error",
                content: t("general.somethingWentWrong"),
            }).then()
        })
    };

    const handleSubmitDelete = () => {
        deleteAccount(selectedId).then(() => {
            messageApi.open({
                type: "success",
                content: t("gateInfo.msg.delete"),
            }).then(r => {
            });
            loadAccounts()
            setDeleteModal(false);
        })
    };

    const handleCancel = () => {
        setModal(false);
        setDeleteModal(false);
        setChangePasswordModal(false);
        setCheckedList([]);
        dataForm.resetFields();
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
        setAccountQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadAccounts()
    };

    const pageChange: PaginationProps['onChange'] = (page, size) => {
        setAccountQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadAccounts(page, size)
    };

    const onChangeNameSearch = (e: any) => {
        setNameValue(e.target.value)
        setAccountQueryForm((prevPagination) => ({
            ...prevPagination,
            name: e.target.value
        }));
        loadAccounts(accountQueryForm.page, accountQueryForm.size, e.target.value, accountQueryForm.mobile, accountQueryForm.permission, accountQueryForm.project)
    };

    const onChangePhoneSearch = (e: any) => {
        setPhoneValue(e.target.value)
        setAccountQueryForm((prevPagination) => ({
            ...prevPagination,
            mobile: e.target.value
        }));
        loadAccounts(accountQueryForm.page, accountQueryForm.size, accountQueryForm.name, e.target.value, accountQueryForm.permission, accountQueryForm.project)
    };

    const onChangePermission = (value: any) => {
        setPermissionValue(value)
        setAccountQueryForm((prevPagination) => ({
            ...prevPagination,
            permission: value
        }));
        loadAccounts(accountQueryForm.page, accountQueryForm.size, accountQueryForm.name, accountQueryForm.mobile, value, accountQueryForm.project)
    };

    const onChangeProject = (value: any) => {
        setProjectValue(value)
        setAccountQueryForm((prevPagination) => ({
            ...prevPagination,
            project: value
        }));
        loadAccounts(accountQueryForm.page, accountQueryForm.size, accountQueryForm.name, accountQueryForm.mobile, accountQueryForm.permission, value)
    };

    const handleResetFilter = () => {
        setNameValue("")
        setPhoneValue("")
        setPermissionValue(null)
        setProjectValue(null)
        setAccountQueryForm((prevPagination) => ({
            ...prevPagination,
            name: null,
            mobile: null,
            permission: null,
            project: null
        }));
        loadAccounts(accountQueryForm.page, accountQueryForm.size, null, null, null, null)
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const columns: ColumnsType<AccountData> = [
        {
            title: t("acManagement.name"),
            dataIndex: "name",
            key: "name",
            width: "8%",
            //   sorter: (a, b) => a.name.length - b.name.length,
            // ...getColumnSearchProps("name"),
        },
        {
            title: t("acManagement.phone"),
            dataIndex: "phone",
            key: "phone",
            width: "8%",
            //   sorter: (a, b) => a.phone.length - b.phone.length,
            // ...getColumnSearchProps("phone"),
        },
        {
            title: t("acManagement.permission"),
            dataIndex: "permission",
            key: "permission",
            width: "18%",
            //   sorter: (a, b) => a.permission.length - b.permission.length,
            render: (data, details, index) =>
                JSON.parse(data).map((permission: any) => {
                    let text = "";
                    switch (permission) {
                        case "user.view":
                            text = t("acManagement.userInfo");
                            break;
                        case "ac.view":
                            text = t("acManagement.acManagement");
                            break;
                        case "unlock.view":
                            text = t("acManagement.unlockLog");
                            break;
                        case "block.view":
                            text = t("acManagement.blockInfo");
                            break;
                        case "gateAdmin.view":
                            text = t("acManagement.gateInfoAdmin");
                            break;
                        case "gateTechnician.view":
                            text = t("acManagement.gateInfoTechnician");
                            break;
                        case "card.view":
                            text = t("acManagement.cardInfo");
                            break;
                        case "zone.view":
                            text = t("acManagement.zoneInfo");
                            break;
                    }
                    return (
                        <Tag
                            color="processing"
                            style={{fontWeight: "bold", fontSize: "13px", marginTop: "5px"}}
                        >
                            {text}
                        </Tag>
                    );
                }),
        },
        {
            title: t("acManagement.project"),
            dataIndex: "project",
            key: "project",
            width: "18%",
            //   sorter: (a, b) => a.project.length - b.project.length,
            render: (data, details, index) =>
                JSON.parse(data).map((id: number) => {
                    let project = projectList.find(project => project.id === id);
                    return (
                        <Tag
                            color="success"
                            style={{
                                fontWeight: "bold",
                                fontSize: "13px",
                                marginTop: "5px",
                            }}
                        >
                            {project?.name}
                        </Tag>
                    );
                }),
        },
        {
            title: t("general.action"),
            key: "action",
            width: "15%",
            render: (data, details, index) => (
                <Space direction="vertical" size="middle" style={{display: "flex"}}>
                    <Space size="middle">
                        <Button
                            type="primary"
                            className="actionButton"
                            onClick={() => showModal("edit", details)}
                        >
                            <EditOutlined/>
                            {t("general.permission")}
                        </Button>
                        <Button
                            type="primary"
                            danger
                            className="actionButton"
                            onClick={() => showModal("delete", details)}
                        >
                            <DeleteOutlined/> {t("general.delete")}
                        </Button>
                    </Space>
                    <Space>
                        <Button
                            type="primary"
                            className="actionButton"
                            style={{backgroundColor: "#FFAC1C"}}
                            onClick={() => showModal("password", details)}
                        >
                            <RedoOutlined/>
                            {t("acManagement.changePassword")}
                        </Button>
                    </Space>
                </Space>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.acManagement")}</Row>
                <Row>
                    <Input
                        prefix={<UserOutlined/>}
                        allowClear
                        value={nameValue}
                        placeholder={t("userInfo.searchNamePlaceholder")}
                        onChange={onChangeNameSearch}
                        className="searchBar"
                    />
                    <Input
                        prefix={<PhoneOutlined/>}
                        allowClear
                        value={phoneValue}
                        placeholder={t("userInfo.searchPhonePlaceholder")}
                        onChange={onChangePhoneSearch}
                        className="searchBar"
                        type="number"
                    />
                    <Select
                        allowClear
                        showSearch
                        placeholder="Permission"
                        optionFilterProp="children"
                        onChange={onChangePermission}
                        filterOption={filterOption}
                        className="permissionBar"
                        size={"large"}
                        value={permissionValue}
                        options={permission}
                    />
                    <Select
                        allowClear
                        showSearch
                        placeholder="Project"
                        optionFilterProp="children"
                        onChange={onChangeProject}
                        filterOption={filterOption}
                        className="projectBar"
                        size={"large"}
                        value={projectValue}
                        options={projectList.map(item => ({
                            label: item.name,
                            value: item.id.toString()
                        }))}
                    />
                    <Button
                        danger
                        type="primary"
                        onClick={handleResetFilter}
                        className="resetButton"
                    >
                        <ReloadOutlined/> {t("general.reset")}
                    </Button>
                    <Button
                        type="primary"
                        className="addNewButton"
                        onClick={() => showModal("add", "")}
                    >
                        <PlusCircleOutlined/> {t("general.addNew")}
                    </Button>
                </Row>
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={accountList}
                    />
                </Row>
                <Row justify={"end"}>
                    <Pagination
                        showSizeChanger
                        current={accountQueryForm.page}
                        onShowSizeChange={onShowSizeChange}
                        onChange={pageChange}
                        total={accountQueryForm.total}
                        pageSizeOptions={[5]}
                        pageSize={5}
                    />
                </Row>
            </Space>

            {/* Add New Modal */}
            <Modal
                title={<div className="modalTitle">{t(selectedId ? "general.edit" : "general.addNew")}</div>}
                open={getModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    {...layout}
                    form={dataForm}
                    layout={"vertical"}
                    name="add"
                    onFinish={handleSubmit}
                    style={{marginTop: "30px"}}
                >
                    <Form.Item
                        name="name"
                        label={t("acManagement.userName")}
                        rules={[
                            {required: true, message: t("acManagement.inputErrorName")},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={t("acManagement.phone")}
                        rules={[
                            {
                                required: true,
                                message: t("acManagement.inputErrorPhone"),
                            },
                        ]}
                    >
                        <InputNumber
                            addonBefore="+65"
                            controls={false}
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                    {selectedId ? "" :
                        <Form.Item
                            name="password"
                            label={t("acManagement.password")}
                            rules={[
                                {required: true, message: t("acManagement.inputErrorPassword")},
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>}
                    <Form.Item name="permission" label={t("acManagement.permission")}>
                        <div
                            style={{
                                border: "1px solid #d9d9d9",
                                borderRadius: "6px",
                            }}
                        >
                            <div style={{margin: "10px"}}>
                                <Checkbox.Group
                                    style={{width: "100%"}}
                                    onChange={onChangeCheckBox}
                                    value={checkedList}
                                >
                                    <Row gutter={[0, 16]}>
                                        {permission.map((option) => (
                                            <Col span={12}>
                                                <Checkbox value={option.value}>{option.label}</Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item name="project" label={t("acManagement.project")}>
                        <Select
                            mode="multiple"
                            placeholder="Select project"
                            filterOption={(input: any, option: any) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            style={{width: "100%"}}
                            optionLabelProp="label"
                            options={projectList.map(project => ({label: project.name, value: project.id}))}
                        />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="modalSubmitButton"
                        >
                            {t("general.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Change Password Modal */}
            <Modal
                title={
                    <div className="modalTitle">{t("acManagement.changePassword")}</div>
                }
                open={changePasswordModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    {...layout}
                    form={password_form}
                    layout={"vertical"}
                    name="edit"
                    onFinish={handleSubmitChangePassword}
                    style={{marginTop: "30px"}}
                >
                    <Form.Item
                        name="password"
                        label={t("acManagement.password")}
                        rules={[
                            {
                                required: true,
                                message: "Please enter your New Password!",
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="modalSubmitButton"
                        >
                            {t("general.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal
                title={<div className="modalTitle">{t("general.delete")}</div>}
                open={deleteModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t("general.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmitDelete}>
                        {t("general.confirm")}
                    </Button>,
                ]}
            >
                <div>
                    {t("general.delete_one")}
                    <b>{t("general.delete_five")}</b>
                    {t("general.delete_three")}
                </div>
            </Modal>
        </div>
    );
}
