import {createSlice} from "@reduxjs/toolkit";

const initialState = {};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            Reflect.set(state, "id", action.payload.id);
            Reflect.set(state, "name", action.payload.name);
            Reflect.set(state, "phone", action.payload.phone);
            Reflect.set(state, "language", action.payload.language);
            Reflect.set(state, "permission", action.payload.permission);
            Reflect.set(state, "project", action.payload.project);
        },
        changeLanguage: (state, action) => {
            Reflect.set(state, "language", action.payload.language);

        },
        logout: () => initialState,
    },
});

export const {login, logout, changeLanguage} = userSlice.actions;
export default userSlice.reducer;
