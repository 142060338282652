import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Modal, Pagination, PaginationProps, Row, Select, Space, Table, Tag} from "antd";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined, ReloadOutlined, SearchOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {ColumnsType} from "antd/es/table";
import {ZoneData} from "../../domain/zone";
import {listGates, zones} from "../../http";
import {createZone, deleteZone, updateZone} from "../../http/zone";
import {GateData} from "../../domain/gate";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 24},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function ZoneInfoPage() {
    const [dataForm] = Form.useForm();
    //multilanguage
    const {t} = useTranslation();

    //modal
    const [getModal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    //search
    const [zoneValue, setZoneValue] = useState("");
    const [gateValue, setGateValue] = useState(null);

    //message
    const [messageApi, contextHolder] = message.useMessage();

    const project = useSelector((state: any) => state.project);
    const [zoneList, setZoneList] = useState([] as Array<ZoneData>);
    const [gateList, setGateList] = useState([] as Array<GateData>);

    const [zoneQueryForm, setZoneQueryForm] = useState({
        page: 1,
        size: 10,
        total: 0,
        name: null,
        gate: null
    });


    useEffect(() => {
        loadGates();
        loadZones();
    }, [project]);

    const loadZones = (page = zoneQueryForm.page,
                       size = zoneQueryForm.size,
                       name = zoneQueryForm.name,
                       gate = zoneQueryForm.gate
    ) => {
        let query = {
            page: page,
            size: size,
            name: name,
            gateId: gate
        }
        zones(query).then(result => {
            console.log(result)
            setZoneQueryForm((prevPagination) => ({
                ...prevPagination,
                total: result.totalElements // Update total count if available from backend
            }));
            setZoneList(result.content as any);
        })
    }

    const loadGates = () => {
        listGates().then(result => setGateList((result as any).reverse()))
    }

    const showModal = (text: any, data: any) => {
        setSelectedId(data.id);
        if (text === "edit") {
            setModal(true);

            dataForm.setFieldValue("name", data.name);
            dataForm.setFieldValue("projectId", data.projectId);
            dataForm.setFieldValue("gate", JSON.parse(data.gate));
            dataForm.setFieldValue("matcher", data.matcher);
        } else if (text === "delete") {
            setDeleteModal(true);
        } else if (text === "add") {
            setModal(true);
        }
    };

    const handleSubmit = (values: any) => {
        let data = {
            ...values,
            "gate": JSON.stringify(values.gate),
            "projectId": project.id,
        };
        const action = selectedId ? updateZone(selectedId, data) : createZone(data)
        action.then(r => {
            messageApi.open({
                type: "success",
                content: t(selectedId ? "zoneInfo.msg.edit" : "zoneInfo.msg.add"),
            }).then(r => {
            });
            setModal(false);
            dataForm.resetFields();
            loadZones()
        }).catch(() => {
            messageApi.open({
                type: "error",
                content: t("general.somethingWentWrong"),
            }).then()
        })
    };

    const handleSubmitDelete = () => {
        deleteZone(selectedId).then(() => {
            messageApi.open({
                type: "success",
                content: t("gateInfo.msg.delete"),
            }).then(r => {
            });
            loadZones()
            setDeleteModal(false);
        })
    };

    const handleCancel = () => {
        setModal(false);
        setDeleteModal(false);
        dataForm.resetFields();
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
        setZoneQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadZones()
    };

    const pageChange: PaginationProps['onChange'] = (page, size) => {
        setZoneQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadZones(page, size)
    };


    const onChangeNameSearch = (e: any) => {
        setZoneValue(e.target.value)
        setZoneQueryForm((prevPagination) => ({
            ...prevPagination,
            name: e.target.value
        }));
        loadZones(zoneQueryForm.page, zoneQueryForm.size, e.target.value)
    };

    const onChangeGate = (value: any) => {
        setGateValue(value)
        setZoneQueryForm((prevPagination) => ({
            ...prevPagination,
            gate: value
        }));
        loadZones(zoneQueryForm.page, zoneQueryForm.size, zoneQueryForm.name, value)
    };

    const handleResetFilter = () => {
        setZoneValue("")
        setGateValue(null)
        setZoneQueryForm((prevPagination) => ({
            ...prevPagination,
            name: null,
            gateType: null
        }));
        loadZones(zoneQueryForm.page, zoneQueryForm.size, null, null)
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const columns: ColumnsType<ZoneData> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            //   sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Gate",
            dataIndex: "gate",
            key: "gate",
            //   sorter: (a, b) => a.gate.length - b.gate.length,
            render: (gate) => {
                return (
                    <>
                        {JSON.parse(gate).map((gateId: any) => {
                            const foundGate = gateList.find((item) => item.id === gateId);
                            return foundGate ? (
                                <Tag color="geekblue" key={gateId}>
                                    {foundGate.name}
                                </Tag>
                            ) : <Tag color="red">Unknown</Tag>;
                        })}
                    </>
                );
            },
        },
        {
            title: "Matcher",
            dataIndex: "matcher",
            key: "matcher",
            //   sorter: (a, b) => a.matcher.length - b.matcher.length,
        },
        {
            title: t("general.action"),
            key: "action",
            render: (data, details, index) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        className="actionButton"
                        onClick={() => showModal("edit", data)}
                    >
                        <EditOutlined/>
                        {t("general.edit")}
                    </Button>
                    <Button
                        type="primary"
                        danger
                        className="actionButton"
                        onClick={() => showModal("delete", data)}
                    >
                        <DeleteOutlined/> {t("general.delete")}
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.zoneInfo")}</Row>
                <Row>
                    <Input
                        prefix={<SearchOutlined/>}
                        allowClear
                        value={zoneValue}
                        placeholder={t("zoneInfo.searchPlaceholder")}
                        onChange={onChangeNameSearch}
                        className="searchBar"
                    />
                    <Select
                        allowClear
                        showSearch
                        placeholder="Gate"
                        optionFilterProp="children"
                        onChange={onChangeGate}
                        filterOption={filterOption}
                        className="projectBar"
                        size={"large"}
                        value={gateValue}
                        options={gateList.map(item => ({
                            label: item.name,
                            value: item.id.toString()
                        }))}
                    />
                    <Button
                        danger
                        type="primary"
                        onClick={handleResetFilter}
                        className="resetButton"
                    >
                        <ReloadOutlined/> {t("general.reset")}
                    </Button>
                    <Button
                        type="primary"
                        className="addNewButton"
                        onClick={() => showModal("add", "")}
                    >
                        <PlusCircleOutlined/> {t("general.addNew")}
                    </Button>
                </Row>
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={zoneList}
                    />
                </Row>
                <Row justify={"end"}>
                    <Pagination
                        showSizeChanger
                        current={zoneQueryForm.page}
                        onShowSizeChange={onShowSizeChange}
                        onChange={pageChange}
                        total={zoneQueryForm.total}
                        pageSizeOptions={[10]}
                        pageSize={10}
                    />
                </Row>
            </Space>

            {/* Add New Modal */}
            <Modal
                title={<div className="modalTitle">{t(selectedId ? "general.edit" : "general.addNew")}</div>}
                open={getModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    {...layout}
                    form={dataForm}
                    layout={"vertical"}
                    name="add"
                    onFinish={handleSubmit}
                    style={{marginTop: "30px"}}
                >
                    <Form.Item
                        name="name"
                        label="Zone Name"
                        rules={[{required: true, message: "Please enter the Zone Name!"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="gate"
                        label="Gate"
                        rules={[{required: true, message: "Please select the gate included!"}]}
                    >
                        <Select
                            style={{width: "100%",}}
                            mode="multiple"
                            allowClear
                        >
                            {gateList && gateList.map((gate: any) => (
                                <Select.Option key={gate.id} value={gate.id}>
                                    {gate.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="matcher"
                        label="Unit Matcher"
                        rules={[{required: true, message: "Please enter the matcher!"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="modalSubmitButton"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal
                title={<div className="modalTitle">{t("general.delete")}</div>}
                open={deleteModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmitDelete}>
                        Confirm
                    </Button>,
                ]}
            >
                <div>
                    {t("general.delete_one")}
                    <b>{t("general.delete_five")}</b>
                    {t("general.delete_three")}
                </div>
            </Modal>
        </div>
    );
}
