import {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Image,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps,
    Row,
    Select,
    Space,
    Table,
    Upload
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {ColumnsType} from "antd/es/table";
import {GateData} from "../../domain/gate";
import {createGate, deleteGate, updateGate} from "../../http/gate";
import {pageGates} from "../../http";
import type {RcFile} from 'antd/es/upload/interface';

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function GateInfoPage() {
    const [dataForm] = Form.useForm();
    const initialValuesDataForm = {
        alert: false,
    };

    //multilanguage
    const {t} = useTranslation();

    //modal
    const [getModal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    //search
    const [gateValue, setGateValue] = useState("");
    const [gateTypeValue, setGateTypeValue] = useState(null);

    //message
    const [messageApi, contextHolder] = message.useMessage();

    //get user
    useSelector((state: any) => state.user);
    const [gateList, setGateList] = useState([] as Array<GateData>);


    const [gateQueryForm, setGateQueryForm] = useState({
        page: 1,
        size: 10,
        total: 0,
        name: null,
        gateType: null,
    });

    const project = useSelector((state: any) => state.project);
    const user = useSelector((state: any) => state.user);
    const user_permission = user.permission ? JSON.parse(user.permission) : "";

    useEffect(() => {
        loadGates()
    }, [project]);

    const loadGates = (page = gateQueryForm.page,
                       size = gateQueryForm.size,
                       name = gateQueryForm.name,
                       gateType = gateQueryForm.gateType) => {
        let query = {
            page: page,
            size: size,
            name: name,
            gateType: gateType
        }
        pageGates(query).then(result => {
            setGateQueryForm((prevPagination) => ({
                ...prevPagination,
                total: result.totalElements // Update total count if available from backend
            }));
            setGateList(result.content as any);
        })
    }

    const showModal = (text: any, data: any) => {
        setSelectedId(data.id);
        if (text === "edit") {
            dataForm.setFieldValue("name", data.name);
            dataForm.setFieldValue("mac", data.mac);
            dataForm.setFieldValue("sortRank", data.sortRank);
            dataForm.setFieldValue("gateType", data.gateType);
            dataForm.setFieldValue("alert", data.alert);
            dataForm.setFieldValue("image", data.image);
            setModal(true);
        } else if (text === "delete") {
            setDeleteModal(true);
        } else if (text === "add") {
            setModal(true);
        }
    };

    const handleSubmit = (values: any) => {
        let data = {
            ...values,
            projectId: project.id,
        }
        createGate(data).then(() => {
            messageApi.open({
                type: "success",
                content: t("gateInfo.msg.add"),
            }).then(() => {
            });
            loadGates()
            setModal(false);
            dataForm.resetFields();
        }).catch(() => {
            messageApi.open({
                type: "error",
                content: t("general.somethingWentWrong"),
            }).then()
        })
    };

    const handleSubmitEdit = (values: any) => {
        let data = {
            ...values,
            projectId: project.id,
        }
        updateGate(selectedId, data).then(() => {
            messageApi.open({
                type: "success",
                content: t("gateInfo.msg.edit"),
            }).then(() => {
            });
            loadGates()
            setModal(false);
            dataForm.resetFields();
        })
    };

    const handleSubmitDelete = () => {
        deleteGate(selectedId).then(() => {
            messageApi.open({
                type: "success",
                content: t("gateInfo.msg.delete"),
            }).then(() => {
            });
            loadGates()
            setDeleteModal(false);
        })
    };

    const handleCancel = () => {
        setModal(false)
        setDeleteModal(false);
        dataForm.resetFields();
        setSelectedId(0);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        return isJpgOrPng || Upload.LIST_IGNORE;
    };

    const onRemove = () => {
        dataForm.setFieldValue("image", null);
    }

    const onChangeFile = (info: any) => {
        info.file.status = 'done';

        getBase64(info.file.originFileObj as RcFile, (url) => {
            let base64 = url.split(",");
            dataForm.setFieldValue("image", base64[1]);
        });
    };

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
        setGateQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadGates()
    };

    const pageChange: PaginationProps['onChange'] = (page, size) => {
        setGateQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadGates(page, size)
    };

    const onChangeNameSearch = (e: any) => {
        setGateValue(e.target.value)
        setGateQueryForm((prevPagination) => ({
            ...prevPagination,
            name: e.target.value
        }));
        loadGates(gateQueryForm.page, gateQueryForm.size, e.target.value, gateQueryForm.gateType)
    };

    const onChangeGateType = (value: any) => {
        setGateTypeValue(value)
        setGateQueryForm((prevPagination) => ({
            ...prevPagination,
            gateType: value
        }));
        loadGates(gateQueryForm.page, gateQueryForm.size, gateQueryForm.name, value)
    };

    const handleResetFilter = () => {
        setGateValue("")
        setGateTypeValue(null)
        setGateQueryForm((prevPagination) => ({
            ...prevPagination,
            name: null,
            gateType: null
        }));
        loadGates(gateQueryForm.page, gateQueryForm.size, null, null)
    };

    const formatTimestamp = (timestampArray: any) => {
        if (timestampArray.length === 7) {
            const [year, month, day, hours, minutes, seconds, milliseconds] = timestampArray;

            const date = `${day}/${month}/${year}`;
            const time = `${(hours % 12) || 12}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

            return `${time} ${date}`;
        } else {
            return "Invalid timestamp format";
        }
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const columns: ColumnsType<GateData> = [
        {
            title: t("gateInfo.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("gateInfo.mac"),
            dataIndex: "mac",
            key: "mac",
        },
        {
            title: t("gateInfo.gateType"),
            dataIndex: "gateType",
            key: "gateType",
            render: (data: number) => {
                const typeLabels: Record<number, string> = {
                    1: "Door",
                    2: "Gate",
                    3: "Side Gate",
                    4: "Vehicle Barrier",
                };

                const label = typeLabels[data] || "Unknown Type";

                return <div>{label}</div>;
            },
        },
        {
            title: t("gateInfo.alert"),
            dataIndex: "alert",
            key: "alert",
            render: (data) => (
                <div>
                    {data ? "Yes" : "No"}
                </div>
            ),
        },
        {
            title: t("gateInfo.rank"),
            dataIndex: "sortRank",
            key: "sortRank",
        },
        {
            title: t("gateInfo.image"),
            dataIndex: "image",
            key: "image",
            align: "center",
            width: "4.5%",
            render: (data) => (
                data ?
                    <Image
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                        }}
                        src={
                            'https://s3.eazable.sg/files/' + data
                        }
                    /> : ""
            ),
        },
        {
            title: t("general.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => (
                <div>
                    {createdAt ? formatTimestamp(createdAt) : "N/A"}
                </div>
            ),
        },
        {
            title: t("general.action"),
            key: "action",
            render: (data) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        className="actionButton"
                        onClick={() => showModal("edit", data)}
                    >
                        <EditOutlined/>
                        {t("general.edit")}
                    </Button>
                    <Button
                        type="primary"
                        danger
                        className="actionButton"
                        onClick={() => showModal("delete", data)}
                    >
                        <DeleteOutlined/> {t("general.delete")}
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.gateInfo")}</Row>
                <Row>
                    <Input
                        prefix={<SearchOutlined/>}
                        allowClear
                        value={gateValue}
                        placeholder={t("gateInfo.searchPlaceholder")}
                        onChange={onChangeNameSearch}
                        className="searchBar"
                    />
                    <Select
                        allowClear
                        showSearch
                        placeholder="Gate Type"
                        optionFilterProp="children"
                        onChange={onChangeGateType}
                        filterOption={filterOption}
                        className="gateTypeBar"
                        size={"large"}
                        defaultValue={0}
                        value={gateTypeValue}
                        options={[
                            {value: "1", label: t("gateInfo.door")},
                            {value: "2", label: t("gateInfo.gate")},
                            {value: "3", label: t("gateInfo.sideGate")},
                            {
                                value: "4",
                                label: t("gateInfo.vehicleBarrier"),
                            },
                        ]}
                    />
                    <Button
                        danger
                        type="primary"
                        onClick={handleResetFilter}
                        className="resetButton"
                    >
                        <ReloadOutlined/> {t("general.reset")}
                    </Button>
                    <Button
                        type="primary"
                        className="addNewButton"
                        onClick={() => showModal("add", "")}
                    >
                        <PlusCircleOutlined/> {t("general.addNew")}
                    </Button>
                </Row>
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={gateList}
                    />
                </Row>
                <Row justify={"end"}>
                    <Pagination
                        showSizeChanger
                        current={gateQueryForm.page}
                        onShowSizeChange={onShowSizeChange}
                        onChange={pageChange}
                        total={gateQueryForm.total}
                        pageSizeOptions={[10]}
                        pageSize={10}
                    />
                </Row>
            </Space>

            {/* Add New Modal */}
            <Modal
                title={<div className="modalTitle">{t(selectedId ? "general.edit" : "general.addNew")}</div>}
                open={getModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    {...layout}
                    form={dataForm}
                    layout={"vertical"}
                    name="add"
                    onFinish={selectedId ? handleSubmitEdit : handleSubmit}
                    style={{marginTop: "30px"}}
                    initialValues={initialValuesDataForm}
                >
                    <Form.Item
                        name="name"
                        label={t("gateInfo.name")}
                        rules={[{required: true, message: t("gateInfo.inputErrorName")}]}
                    >
                        <Input readOnly={!user_permission.includes("gateAdmin.view")}/>
                    </Form.Item>
                    <Form.Item
                        name="mac"
                        label={t("gateInfo.intercom")}
                        rules={[
                            {required: true, message: t("gateInfo.inputErrorIntercom")},
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                name="sortRank"
                                label={t("gateInfo.rank")}
                                rules={[{required: true, message: t("gateInfo.inputErrorRank")}]}
                            >
                                <Input readOnly={!user_permission.includes("gateAdmin.view")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="gateType"
                                label={t("gateInfo.gateType")}
                                rules={[
                                    {required: true, message: t("gateInfo.inputErrorGateType")},
                                ]}
                            >
                                <Select
                                    disabled={!user_permission.includes("gateAdmin.view")}
                                    style={{width: "100%"}}
                                    options={[
                                        {value: 1, label: t("gateInfo.door")},
                                        {value: 2, label: t("gateInfo.gate")},
                                        {value: 3, label: t("gateInfo.sideGate")},
                                        {
                                            value: 4,
                                            label: t("gateInfo.vehicleBarrier"),
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="alert"
                        label={t("gateInfo.alert")}
                        valuePropName="checked"
                    >
                        <Checkbox disabled={!user_permission.includes("gateAdmin.view")}>Yes</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="image"
                        label={t("gateInfo.image")}
                        rules={[
                            {required: true, message: t("gateInfo.inputErrorImage")},
                        ]}
                    >
                        <Upload
                            listType="picture"
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            onChange={onChangeFile}
                            onRemove={onRemove}
                            disabled={!user_permission.includes("gateAdmin.view")}
                        >
                            <Button icon={<UploadOutlined/>}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="modalSubmitButton"
                        >
                            {t("general.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>


            {/* Delete Modal */}
            <Modal
                title={<div className="modalTitle">{t("general.delete")}</div>}
                open={deleteModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t("general.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmitDelete}>
                        {t("general.confirm")}
                    </Button>,
                ]}
            >
                <div>
                    {t("general.delete_one")}
                    <b>{t("general.delete_five")}</b>
                    {t("general.delete_three")}
                </div>
            </Modal>
        </div>
    )
}
