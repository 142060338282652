import {useEffect, useState} from "react";
import {Badge, Button, Col, Layout, Menu, Modal, Row, Select, Space, theme,} from "antd";
import {
    BellOutlined,
    ContactsOutlined,
    CreditCardOutlined,
    DownOutlined,
    ExpandOutlined,
    GlobalOutlined,
    LogoutOutlined,
    MacCommandOutlined,
    MergeCellsOutlined,
    ProjectOutlined,
    QuestionCircleOutlined,
    UnlockOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

//redux persist
import {useDispatch, useSelector} from "react-redux";
import {changeLanguage, logout} from "../Slices/userSlice";

//tsx
import ProjectInfoPage from "./MenuItems/ProjectInfoPage";
import IntercomInfoPage from "./MenuItems/IntercomInfoPage";
import GateInfoPage from "./MenuItems/GateInfoPage";
import ZoneInfoPage from "./MenuItems/ZoneInfoPage";
import FeedbackPage from "./MenuItems/FeedbackPage";
import AcManagementPage from "./MenuItems/AcManagementPage";
import CardInfoPage from "./MenuItems/CardInfoPage";
import UnlockLogPage from "./MenuItems/UnlockLogPage";
import {logoutApi, permissionProjects, switchProject} from "../http";
import {changeProject, clear} from "../Slices/projectSlice";
import {ProjectData} from "../domain";
import UserInfoPage from "./MenuItems/UserInfoPage";

const {Header, Content, Sider} = Layout;

export default function MainPage() {
    const {t, i18n} = useTranslation();

    const [collapsed, setCollapsed] = useState(false);
    const [currentTab, setCurrentTab] = useState("projectInfo");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    //fetch data
    const [permissionProjectList, setPermissionProjectList] = useState<ProjectData[]>([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.user);
    const user_permission = user.permission ? JSON.parse(user.permission) : "";
    const user_language = user.language ? user.language : "en";

    const project = useSelector((state: any) => state.project);

    useEffect(() => {
        //get data list
        loadData();
        //set default language from user
        i18n.changeLanguage(user_language);
    }, []);

    useEffect(() => {
        if (user.id === undefined) {
            navigate("/login");
        }
    });

    const loadData = () => {
        permissionProjects().then(result => {
                setPermissionProjectList(result);
                if (result.length > 0) {
                    dispatch(
                        changeProject({
                            id: result[0].id,
                        })
                    );
                }
            }
        );
    };

    const tabs = [
        {
            label: t("menu.intercomInfo"),
            key: "intercomInfo",
            icon: <MacCommandOutlined style={{fontSize: "23px"}}/>,
        },
        {
            label: t("menu.projectInfo"),
            key: "projectInfo",
            icon: <ProjectOutlined style={{fontSize: "23px"}}/>,
        },
        {
            label: t("menu.gateInfo"),
            key: "gateInfo",
            icon: <MergeCellsOutlined style={{fontSize: "23px"}}/>,
            disabled: !(user_permission.includes("gateAdmin.view") || user_permission.includes("gateTechnician.view"))
        },
        {
            label: t("menu.zoneInfo"),
            key: "zoneInfo",
            icon: <ExpandOutlined style={{fontSize: "23px"}}/>,
            disabled: !user_permission.includes("zone.view"),
        },
        {
            label: t("menu.unlockLog"),
            key: "unlockLog",
            icon: <UnlockOutlined style={{fontSize: "23px"}}/>,
            disabled: !user_permission.includes("unlock.view"),
        },
        {
            label: t("menu.acManagement"),
            key: "acManagement",
            icon: <ContactsOutlined style={{fontSize: "23px"}}/>,
            disabled: !user_permission.includes("ac.view"),
        },
        {
            label: t("menu.cardInfo"),
            key: "cardInfo",
            icon: <CreditCardOutlined style={{fontSize: "23px"}}/>,
            disabled: !user_permission.includes("card.view"),
        },
        {
            label: t("menu.userInfo"),
            key: "userInfo",
            icon: <UserOutlined style={{fontSize: "23px"}}/>,
            disabled: !user_permission.includes("user.view"),
        },
        {
            label: t("menu.feedback"),
            key: "feedback",
            icon: <QuestionCircleOutlined style={{fontSize: "23px"}}/>,
        },
        {
            label: t("general.logout"),
            key: "logout",
            icon: <LogoutOutlined style={{fontSize: "23px"}}/>,
            danger: true,
        },
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        logoutApi().then((res) => {
            dispatch(logout());
            dispatch(clear());
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (value: string | undefined) => {
        i18n.changeLanguage(value);

        //save to redux persist
        dispatch(
            changeLanguage({
                language: value,
            })
        );
    };

    const handleChangeCurrentProject = (value: number) => {
        switchProject(value).then((res) => {
            dispatch(
                changeProject({
                    id: value,
                })
            );
        })
    };

    return (
        <Layout
            style={{minHeight: "100vh",}}
        >
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                style={{color: "white", boxShadow: "2px 0px 8px 2px #B2BEB5"}}
            >
                <h2 style={{textAlign: "center"}}>Meta</h2>
                <hr
                    style={{
                        boxShadow: "1px 0px 8px 2px white",
                    }}
                />
                <Menu
                    theme="dark"
                    defaultSelectedKeys={["1"]}
                    mode="inline"
                    onClick={(value) => {
                        setCurrentTab(value.key);
                        if ((value.key = "logout")) {
                            showModal();
                        }
                    }}
                    items={tabs}
                    style={{fontSize: "14px", textAlign: "center"}}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        minHeight: "7vh",
                        boxShadow: "2px 0px 8px 2px #B2BEB5",
                    }}
                >
                    <Row>
                        <Col span={15}>
                            <div
                                style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    marginLeft: "45px",
                                }}
                            >
                                {t("general.ACMS")}
                            </div>
                        </Col>
                        <Col span={9}>
                            <Row
                                justify="end"
                                style={{
                                    marginRight: "30px",
                                }}
                            >
                                <div style={{marginRight: "20px"}}>
                                    <Select
                                        showSearch
                                        style={{width: "10vw",}}
                                        onChange={handleChangeCurrentProject}
                                        filterOption={(input: any, option: any) =>
                                            (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        value={project.id}
                                        options={permissionProjectList.map(project => ({
                                            label: project.name,
                                            value: project.id
                                        }))}
                                    />
                                </div>
                                <div style={{marginRight: "20px"}}>
                                    <Select
                                        defaultValue={user_language}
                                        style={{
                                            width: 120,
                                        }}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: "en",
                                                label: (
                                                    <Space>
                                                        <GlobalOutlined style={{fontSize: "15px"}}/>
                                                        English
                                                    </Space>
                                                ),
                                            },
                                            {
                                                value: "cn",
                                                label: (
                                                    <Space>
                                                        <GlobalOutlined style={{fontSize: "15px"}}/>
                                                        中文
                                                    </Space>
                                                ),
                                            },
                                        ]}
                                    />
                                </div>
                                <Space style={{marginTop: "5px"}}>
                                    <Button type="text">
                                        <Badge count={5}>
                                            <BellOutlined style={{fontSize: "25px"}}/>
                                        </Badge>
                                    </Button>
                                    <Button type="text">
                                        <UserOutlined style={{fontSize: "25px"}}/>
                                        <DownOutlined style={{fontSize: "15px"}}/>
                                    </Button>
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </Header>

                <Content style={{height: "93vh", textAlign: "center"}}>
                    {currentTab === "projectInfo" ? (
                        <ProjectInfoPage/>
                    ) : currentTab === "intercomInfo" ? (
                        <IntercomInfoPage/>
                    ) : currentTab === "gateInfo" ? (
                        <GateInfoPage/>
                    ) : currentTab === "userInfo" ? (
                        <UserInfoPage/>
                    ) : currentTab === "zoneInfo" ? (
                        <ZoneInfoPage/>
                    ) : currentTab === "acManagement" ? (
                        <AcManagementPage/>
                    ) : currentTab === "feedback" ? (
                        <FeedbackPage/>
                    ) : currentTab === "cardInfo" ? (
                        <CardInfoPage/>
                    ) : currentTab === "unlockLog" ? (
                        <UnlockLogPage/>
                    ) : currentTab === "logout" ? (
                        <Modal
                            title={t("general.logout")}
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                        >
                            <p>{t("general.confirmLogout")}</p>
                        </Modal>
                    ) : (
                        "null"
                    )}
                </Content>
            </Layout>
        </Layout>
    );
}
