import React, {useState} from "react";
import {Button, Form, Input, message, Spin,} from "antd";
import {LockOutlined, PhoneOutlined,} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

import {loginApi} from "../http"
// import AGSI from "./../Images/agsi.png";
//redux persist
import {useDispatch} from "react-redux";
import {login} from "../Slices/userSlice";

export default function LoginPage() {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = (values: any) => {
        setLoading(true);
        loginApi(values).then((res) => {
            //save to redux persist
            dispatch(
                login(res)
            );
            messageApi.open({
                type: "success",
                content: "Successfully Logged In!.",
            })
            setTimeout(() => {
                navigate("/main");
            }, 800);

        }).catch((err) => {
            messageApi.open({
                type: "error",
                content: "Wrong credentials!",
            })
        });
        setLoading(false);
    };

    return (
        <div>
            {contextHolder}
            <div className="mainContainer">
                <div className="subMainContainer">
                    <Form
                        name="login"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <div>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your Phone Number!",
                                    },
                                    {
                                        message: "Invalid Phone Numer",
                                        pattern: new RegExp(/^[0-9]*$/),
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <PhoneOutlined
                                            className="site-form-item-icon"
                                            style={{fontSize: "25px"}}
                                        />
                                    }
                                    placeholder="Phone Number"
                                    style={{width: "18vw", height: "5vh", fontSize: "18px"}}
                                />
                            </Form.Item>
                        </div>
                        <br/>
                        <div>
                            <Form.Item
                                name="password"
                                rules={[
                                    {required: true, message: "Please enter your Password!"},
                                ]}
                            >
                                <Input.Password
                                    prefix={
                                        <LockOutlined
                                            className="site-form-item-icon"
                                            style={{fontSize: "25px"}}
                                        />
                                    }
                                    type="password"
                                    placeholder="Password"
                                    className="loginInput"
                                />
                            </Form.Item>
                        </div>
                        <br/>

                        <div className="loginButton">
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button loginInput"
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </div>
                        <Spin spinning={loading}/>
                    </Form>
                </div>
            </div>
        </div>
    );
}
