import {useEffect, useState} from "react";
import {Avatar, Pagination, PaginationProps, Row, Space, Table,} from "antd";
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {ColumnsType} from "antd/es/table";
import {CardData} from "../../domain/card";
import {pageProjects, secrets} from "../../http";

export default function CardInfoPage(props: {}) {
    //multilanguage
    const {t} = useTranslation();

    const [cardList, setCardList] = useState([] as Array<CardData>);

    const project = useSelector((state: any) => state.project);

    const [secretQueryForm, setSecretQueryForm] = useState({
        page: 1,
        size: 10,
        total: 0,
    });

    useEffect(() => {
        loadSecrets()
    }, [project]);

    const loadSecrets = (page = secretQueryForm.page, size = secretQueryForm.size) => {
        let query = {
            page: page,
            size: size
        }
        secrets(query).then(result => {
            setSecretQueryForm((prevPagination) => ({
                ...prevPagination,
                total: result.totalElements // Update total count if available from backend
            }));
            setCardList((result.content as any).reverse());
        })
    }

    // const handleCancel = () => {
    //     setModal(false);
    //     setDeleteModal(false);
    //     setUnitDisabled(true);
    //     dataForm.resetFields();
    // };

    // const showModal = (text: any, data: any) => {
    //     setSelectedId(data.id);
    //     if (text === "add") {
    //         setModal(true);
    //     } else if (text === "edit") {
    //         setModal(true);
    //         setUnitDisabled(false);
    //         dataForm.setFieldValue("projectId", data.projectId);
    //         dataForm.setFieldValue("unitId", data.unitId);
    //         dataForm.setFieldValue("cardNumber", data.cardNumber);
    //         dataForm.setFieldValue("systemNumber", data.systemNumber);
    //     } else if (text === "delete") {
    //         setDeleteModal(true);
    //     }
    // };

    // const handleSubmit = (values: any) => {
    //     const action = selectedId ? updateCard(selectedId, values) : createCard(values)
    //     action.then(r => {
    //         messageApi.open({
    //             type: "success",
    //             content: t(selectedId ? "projectInfo.msg.edit" : "projectInfo.msg.add"),
    //         }).then(r => {
    //         });
    //         setModal(false);
    //         dataForm.resetFields();
    //         loadSecrets()
    //     }).catch(() => {
    //         messageApi.open({
    //             type: "error",
    //             content: t("general.somethingWentWrong"),
    //         }).then()
    //     })
    // };

    // const handleSubmitDelete = () => {
    //     deleteCard(selectedId).then(() => {
    //         messageApi.open({
    //             type: "success",
    //             content: t("gateInfo.msg.delete"),
    //         }).then(r => {
    //         });
    //         loadSecrets()
    //         setDeleteModal(false);
    //     })
    // };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
        setSecretQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadSecrets()
    };

    const pageChange: PaginationProps['onChange'] = (page, size) => {
        setSecretQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadSecrets(page, size)
    };


    const columns: ColumnsType<CardData> = [
        {
            title: t("cardInfo.unit"),
            dataIndex: "unitName",
            key: "unitName",
            //   sorter: (a, b) => a.system_number.length - b.system_number.length,
            // ...getColumnSearchProps("system_number"),
        },
        {
            title: t("cardInfo.user"),
            dataIndex: "userName",
            key: "userName",
            //   sorter: (a, b) => a.system_number.length - b.system_number.length,
            // ...getColumnSearchProps("system_number"),
        },
        {
            title: t("cardInfo.data"),
            dataIndex: "data",
            key: "data",
            ellipsis: true,
            //   sorter: (a, b) => a.system_number.length - b.system_number.length,
            // ...getColumnSearchProps("system_number"),
        },
        {
            title: t("cardInfo.name"),
            dataIndex: "name",
            key: "name",
            align: "center",
            width: "4.5%",
            //   sorter: (a, b) => a.system_number.length - b.system_number.length,
            // ...getColumnSearchProps("system_number"),
            render: (data) => (
                data ? (
                    <Avatar
                        size={40}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                        }}
                        src={data}
                    />
                ) : (
                    ""
                )
            ),
        },
        {
            title: t("cardInfo.secretType"),
            dataIndex: "secretType",
            key: "secretType",
            //   sorter: (a, b) => a.system_number.length - b.system_number.length,
            // ...getColumnSearchProps("system_number"),
            render: (data: number) => {
                const typeLabels: Record<number, string> = {
                    1: "OTP",
                    2: "CARD",
                    3: "BLU",
                    4: "MOBILE",
                    5: "FACE"
                };

                const label = typeLabels[data] || "Unknown Type";

                return <div>{label}</div>;
            },
        },
        {
            title: t("cardInfo.sync"),
            dataIndex: "sync",
            key: "sync",
            //   sorter: (a, b) => a.system_number.length - b.system_number.length,
            // ...getColumnSearchProps("system_number"),
            render: (data, details, index) => {
                return data ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize: '25px'}}/> :
                    <CloseCircleTwoTone twoToneColor="#eb2f96" style={{fontSize: '25px'}}/>;
            },
        },
    ];

    return (
        <div>
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.cardInfo")}</Row>
                {/*<Row>*/}
                {/*    <Button*/}
                {/*        type="primary"*/}
                {/*        className="addNewButton"*/}
                {/*        onClick={() => showModal("add", "")}*/}
                {/*    >*/}
                {/*        <PlusCircleOutlined/> {t("general.addNew")}*/}
                {/*    </Button>*/}
                {/*</Row>*/}
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={cardList}
                    />
                </Row>
                <Row justify={"end"}>
                    <Pagination
                        showSizeChanger
                        current={secretQueryForm.page}
                        onShowSizeChange={onShowSizeChange}
                        onChange={pageChange}
                        total={secretQueryForm.total}
                        pageSizeOptions={[10]}
                        pageSize={10}
                    />
                </Row>
            </Space>

            {/* Add New Modal */}
            {/*<Modal*/}
            {/*    title={<div className="modalTitle">{t(selectedId ? "general.edit" : "general.addNew")}</div>}*/}
            {/*    open={getModal}*/}
            {/*    // onOk={handleOk}*/}
            {/*    onCancel={handleCancel}*/}
            {/*    footer={[]}*/}
            {/*>*/}
            {/*    <Form*/}
            {/*        {...layout}*/}
            {/*        form={dataForm}*/}
            {/*        layout={"vertical"}*/}
            {/*        name="add"*/}
            {/*        onFinish={handleSubmit}*/}
            {/*        style={{marginTop: "30px"}}*/}
            {/*    >*/}
            {/*        <Form.Item*/}
            {/*            name="projectId"*/}
            {/*            label={t("cardInfo.project")}*/}
            {/*            rules={[*/}
            {/*                {required: true, message: t("cardInfo.inputErrorProject")},*/}
            {/*            ]}*/}
            {/*        >*/}
            {/*            <Input/>*/}
            {/*            /!*<Select*!/*/}
            {/*            /!*    showSearch*!/*/}
            {/*            /!*    style={{width: "100%"}}*!/*/}
            {/*            /!*    placeholder={t("cardInfo.searchProject")}*!/*/}
            {/*            /!*    onChange={handleChangeProjectInModal}*!/*/}
            {/*            /!*    filterOption={(input: any, option: any) =>*!/*/}
            {/*            /!*        (option?.label.toLowerCase() ?? "").includes(*!/*/}
            {/*            /!*            input.toLowerCase()*!/*/}
            {/*            /!*        )*!/*/}
            {/*            /!*    }*!/*/}
            {/*            /!*    filterSort={(optionA: any, optionB: any) =>*!/*/}
            {/*            /!*        (optionA?.label ?? "")*!/*/}
            {/*            /!*            .toLowerCase()*!/*/}
            {/*            /!*            .localeCompare((optionB?.label ?? "").toLowerCase())*!/*/}
            {/*            /!*    }*!/*/}
            {/*            /!*    options={props.filteredProjectList.map((d: any) => ({*!/*/}
            {/*            /!*        value: d.id,*!/*/}
            {/*            /!*        label: d.name,*!/*/}
            {/*            /!*    }))}*!/*/}
            {/*/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item*/}
            {/*            name="unitId"*/}
            {/*            label={t("cardInfo.unit")}*/}
            {/*            tooltip="Kindly select project first."*/}
            {/*            rules={[{required: true, message: t("cardInfo.inputErrorUnit")}]}*/}
            {/*        >*/}
            {/*            <Input/>*/}
            {/*            /!*<Select*!/*/}
            {/*            /!*    showSearch*!/*/}
            {/*            /!*    style={{width: "100%"}}*!/*/}
            {/*            /!*    placeholder={t("cardInfo.searchUnit")}*!/*/}
            {/*            /!*    disabled={unitDisabled}*!/*/}
            {/*            /!*    filterOption={(input: any, option: any) =>*!/*/}
            {/*            /!*        (option?.label.toLowerCase() ?? "").includes(*!/*/}
            {/*            /!*            input.toLowerCase()*!/*/}
            {/*            /!*        )*!/*/}
            {/*            /!*    }*!/*/}
            {/*            /!*    filterSort={(optionA: any, optionB: any) =>*!/*/}
            {/*            /!*        (optionA?.label ?? "")*!/*/}
            {/*            /!*            .toLowerCase()*!/*/}
            {/*            /!*            .localeCompare((optionB?.label ?? "").toLowerCase())*!/*/}
            {/*            /!*    }*!/*/}
            {/*            /!*    options={filteredUnitList.map((d: any) => ({*!/*/}
            {/*            /!*        value: d.id,*!/*/}
            {/*            /!*        label: `Block ${d.block}, ${d.unit}`,*!/*/}
            {/*            /!*    }))}*!/*/}
            {/*            />*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item*/}
            {/*            name="cardNumber"*/}
            {/*            label={t("cardInfo.cardNumber")}*/}
            {/*            rules={[*/}
            {/*                {required: true, message: t("cardInfo.inputErrorCardNumber")},*/}
            {/*            ]}*/}
            {/*        >*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item*/}
            {/*            name="systemNumber"*/}
            {/*            label={t("cardInfo.systemNumber")}*/}
            {/*            rules={[*/}
            {/*                {required: true, message: t("cardInfo.inputErrorSystemNumber")},*/}
            {/*            ]}*/}
            {/*        >*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item {...tailLayout}>*/}
            {/*            <Button*/}
            {/*                type="primary"*/}
            {/*                htmlType="submit"*/}
            {/*                className="modalSubmitButton"*/}
            {/*            >*/}
            {/*                {t("general.submit")}*/}
            {/*            </Button>*/}
            {/*        </Form.Item>*/}
            {/*    </Form>*/}
            {/*</Modal>*/}

            {/*/!* Delete Modal *!/*/}
            {/*<Modal*/}
            {/*    title={<div className="modalTitle">{t("general.delete")}</div>}*/}
            {/*    open={deleteModal}*/}
            {/*    // onOk={handleOk}*/}
            {/*    onCancel={handleCancel}*/}
            {/*    footer={[*/}
            {/*        <Button key="back" onClick={handleCancel}>*/}
            {/*            {t("general.cancel")}*/}
            {/*        </Button>,*/}
            {/*        <Button key="submit" type="primary" onClick={handleSubmitDelete}>*/}
            {/*            {t("general.confirm")}*/}
            {/*        </Button>,*/}
            {/*    ]}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        {t("general.delete_one")}*/}
            {/*        <b>{t("general.delete_five")}</b>*/}
            {/*        {t("general.delete_three")}*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </div>
    );
}
