import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps,
    Row,
    Select,
    Space,
    Table,
    Tag
} from "antd";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined, ReloadOutlined, SearchOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import type {ColumnsType} from "antd/es/table";
import {createProject, deleteProject, updateProject} from "../../http/project";
import {pageProjects} from "../../http";
import {ProjectData} from "../../domain";

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function ProjectInfoPage(props: {}) {
    const [dataForm] = Form.useForm();
    const initialValuesDataForm = {
        smsCheckbox: false,
        sms: [],
    };

    //multilanguage
    const {t} = useTranslation();

    //modal
    const [getModal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    //search
    const [projectValue, setProjectValue] = useState("");

    //message
    const [messageApi, contextHolder] = message.useMessage();

    const [displaySelect, setDisplaySelect] = useState(false);

    const [projectList, setProjectList] = useState([] as Array<ProjectData>);

    const [projectQueryForm, setProjectQueryForm] = useState({
        page: 1,
        size: 10,
        total: 0,
        name: null,
    });

    useEffect(() => {
        loadProjects();
    }, []);

    const loadProjects = (page = projectQueryForm.page, size = projectQueryForm.size, name = projectQueryForm.name) => {
        let query = {
            page: page,
            size: size,
            name: name
        }
        pageProjects(query).then(result => {
            setProjectQueryForm((prevPagination) => ({
                ...prevPagination,
                total: result.totalElements // Update total count if available from backend
            }));
            setProjectList(result.content as any);
        })
    }

    const showModal = (text: any, data: any) => {
        setSelectedId(data.id);
        if (text === "edit") {
            dataForm.setFieldValue("name", data.name)
            dataForm.setFieldValue("appId", data.appId)
            dataForm.setFieldValue("unitCodeRule", data.unitCodeRule)
            if (JSON.parse(data.sms).length > 0) {
                setDisplaySelect(true);
                dataForm.setFieldValue("sms", JSON.parse(data.sms));
            }
            setModal(true);
        } else if (text === "delete") {
            setDeleteModal(true);
        } else if (text === "add") {
            setModal(true);
        }
    };

    const handleSubmit = (values: any) => {
        let data = !displaySelect ? {sms: JSON.stringify([]), ...values} : {...values, sms: JSON.stringify(values.sms)};

        const action = selectedId ? updateProject(selectedId, data) : createProject(data)
        action.then(r => {
            messageApi.open({
                type: "success",
                content: t(selectedId ? "projectInfo.msg.edit" : "projectInfo.msg.add"),
            }).then(r => {
            });
            setModal(false);
            dataForm.resetFields();
            loadProjects()
        }).catch(() => {
            messageApi.open({
                type: "error",
                content: t("general.somethingWentWrong"),
            }).then()
        })
    };

    const handleSubmitDelete = () => {
        deleteProject(selectedId).then(() => {
            messageApi.open({
                type: "success",
                content: t("projectInfo.msg.delete"),
            }).then(r => {
            });
            loadProjects()
            setDeleteModal(false);
        })
    };

    const handleCancel = () => {
        setModal(false);
        setDeleteModal(false);
        dataForm.resetFields();
        setDisplaySelect(false);
    };

    const handleChangeCheckbox = (e: any) => {
        setDisplaySelect(e.target.checked);
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
        setProjectQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadProjects()
    };

    const pageChange: PaginationProps['onChange'] = (page, size) => {
        setProjectQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadProjects(page, size)
    };

    const onChangeProjectSearch = (e: any) => {
        setProjectValue(e.target.value)
        setProjectQueryForm((prevPagination) => ({
            ...prevPagination,
            name: e.target.value
        }));
        loadProjects(projectQueryForm.page, projectQueryForm.size, e.target.value)
    };

    const handleResetFilter = () => {
        setProjectValue("")
        setProjectQueryForm((prevPagination) => ({
            ...prevPagination,
            name: null
        }));
        loadProjects(projectQueryForm.page, projectQueryForm.size, null)
    };

    const columns: ColumnsType<ProjectData> = [
        {
            title: t("general.name"),
            dataIndex: "name",
            key: "name",
            width: "35%",
            //   sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: t("gateInfo.sms"),
            dataIndex: "sms",
            key: "sms",
            render: (data) => (
                <div>
                    {JSON.parse(data) && JSON.parse(data).length > 0 ? (
                        JSON.parse(data).map((phoneNumber: any) => (
                            <Tag color="blue" key={phoneNumber}>{phoneNumber}</Tag>
                        ))
                    ) : (
                        <span>No</span>
                    )}
                </div>
            ),
        },
        {
            title: t("general.action"),
            key: "action",
            width: "25%",
            render: (data, details, index) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        className="actionButton"
                        onClick={() => showModal("edit", details)}
                    >
                        <EditOutlined/>
                        {t("general.edit")}
                    </Button>
                    <Button
                        type="primary"
                        danger
                        className="actionButton"
                        onClick={() => showModal("delete", details)}
                    >
                        <DeleteOutlined/> {t("general.delete")}
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.projectInfo")}</Row>
                <Row>
                    <Input
                        prefix={<SearchOutlined/>}
                        allowClear
                        value={projectValue}
                        placeholder={t("projectInfo.searchPlaceholder")}
                        onChange={onChangeProjectSearch}
                        className="searchBar"
                    />
                    <Button
                        danger
                        type="primary"
                        onClick={handleResetFilter}
                        className="resetButton"
                    >
                        <ReloadOutlined/> {t("general.reset")}
                    </Button>
                    <Button
                        type="primary"
                        className="addNewButton"
                        onClick={() => showModal("add", "")}
                    >
                        <PlusCircleOutlined/> {t("general.addNew")}
                    </Button>
                </Row>
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={projectList}
                    />
                </Row>
                <Row justify={"end"}>
                    <Pagination
                        showSizeChanger
                        current={projectQueryForm.page}
                        onShowSizeChange={onShowSizeChange}
                        onChange={pageChange}
                        total={projectQueryForm.total}
                        pageSizeOptions={[10]}
                        pageSize={10}
                    />
                </Row>
            </Space>

            {/* Add New Modal */}
            <Modal
                title={<div className="modalTitle">{t(selectedId ? "general.edit" : "general.addNew")}</div>}
                open={getModal}
                onCancel={handleCancel}
                footer={[]}>
                <Form
                    {...layout}
                    form={dataForm}
                    layout={"vertical"}
                    name="add"
                    onFinish={handleSubmit}
                    style={{marginTop: "30px"}}
                    initialValues={initialValuesDataForm}
                >
                    <Form.Item
                        name="name"
                        label={t("projectInfo.name")}
                        rules={[
                            {required: true, message: t("projectInfo.inputErrorName")},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="appId"
                        label={t("projectInfo.appId")}
                        rules={[
                            {required: true, message: t("projectInfo.inputErrorAppId")},
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="unitCodeRule"
                        label={t("projectInfo.unitCodeRule")}
                        rules={[
                            {required: true, message: t("projectInfo.inputErrorUnitCodeRule")},
                        ]}
                    >
                        <Select
                            style={{width: "100%"}}
                            options={[
                                {value: 1, label: t("projectInfo.full")},
                                {value: 2, label: t("projectInfo.unit")},
                                {value: 3, label: t("projectInfo.partOfUnit")},
                            ]}
                        />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label={t("gateInfo.sms")}
                            >
                                <Checkbox checked={displaySelect} onChange={handleChangeCheckbox}>Yes</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            {displaySelect && (
                                <Form.Item
                                    label=" "
                                    name="sms"
                                    rules={[
                                        {required: true, message: t("gateInfo.inputErrorProject")},
                                    ]}
                                >
                                    <Select
                                        mode="tags"
                                        style={{width: '100%'}}
                                        placeholder="Phone Number"
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Form.Item {...tailLayout}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="modalSubmitButton"
                        >
                            {t("general.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal
                title={<div className="modalTitle">{t("general.delete")}</div>}
                open={deleteModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t("general.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmitDelete}>
                        {t("general.confirm")}
                    </Button>,
                ]}
            >
                <div>
                    {t("general.delete_one")}
                    <b>{t("general.delete_five")}</b>
                    {t("general.delete_three")}
                </div>
            </Modal>
        </div>
    );
}
