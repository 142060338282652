import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

const URL: string = '/v1/api/mg'

enum RequestEnums {
    TIMEOUT = 20000,
}

const config = {
    // 默认地址
    baseURL: URL as string,
    // 设置超时时间
    timeout: RequestEnums.TIMEOUT as number,
    // 跨域时候允许携带凭证
    withCredentials: true
}

function covertTime(params: object | undefined): object | undefined {
    if (!params) {
        return params
    }
    const clone = Object.assign({}, params);
    for (const ownKey of Reflect.ownKeys(clone)) {
        const arr = Reflect.get(clone, ownKey);
        if (arr instanceof Array && arr.length === 2) {
            Reflect.set(clone, ownKey, null)
            Reflect.set(clone, `${ownKey as string}Start`, arr[0])
            Reflect.set(clone, `${ownKey as string}End`, arr[1])
        }
    }
    return clone;
}

class RequestHttp {
    // 定义成员变量并指定类型
    service: AxiosInstance;

    public constructor(config: AxiosRequestConfig<any>) {
        // 实例化axios
        this.service = axios.create(config);

        /**
         * 请求拦截器
         * 客户端发送请求 -> [请求拦截器] -> 服务器
         * token校验(JWT) : 接受服务器返回的token,存储到vuex/pinia/本地储存当中
         */
        this.service.interceptors.request.use(null,
            (error: AxiosError) => {
                return Promise.reject(error)
            }
        )

        /**
         * 响应拦截器
         * 服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
         */
        this.service.interceptors.response.use(
            (response: AxiosResponse) => {
                return response
            },
            (error: AxiosError) => {
                const {response} = error;
                if (response?.status === 401) {
                    window.location.href = "/login";
                    return Promise.reject();
                }
                if (response?.status === 404) {
                    return Promise.reject();
                } else {
                    if (response?.status === 400 && response?.data) {
                        const errCode = Reflect.get(response?.data as Object, 'code');
                        // const msg = messages[errCode];
                        //display backend error message
                        return Promise.reject();
                    }
                    return Promise.reject(response?.data);
                }
            }
        )
    }

    // 常用方法封装
    get<T>(url: string, params?: object): Promise<T> {
        return this.service.get(url, {params: covertTime(params)})
            .then(res => res.data)
    }


    post<T>(url: string, params?: object): Promise<T> {
        return this.service.post(url, params).then(res => res.data);
    }

    put<T>(url: string, params?: object): Promise<T> {
        return this.service.put(url, params);
    }

    patch<T>(url: string, params?: object): Promise<T> {
        return this.service.patch(url, params);
    }

    delete<T>(url: string, params?: object): Promise<T> {
        return this.service.delete(url, {params});
    }
}

export const httpClient = new RequestHttp(config);

