import {useEffect, useState} from "react";
import {Button, Input, message, Modal, Pagination, PaginationProps, Row, Space, Table} from "antd";
import {DeleteOutlined, ReloadOutlined, SearchOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {ColumnsType} from "antd/es/table";
import {IntercomData} from "../../domain/intercom";
import {intercoms} from "../../http";

export default function IntercomInfoPage(props: {}) {
    //multilanguage
    const {t} = useTranslation();

    //modal
    const [selectedId, setSelectedId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    //search
    const [macValue, setMacValue] = useState("");

    //message
    const [messageApi, contextHolder] = message.useMessage();

    //get user
    const user = useSelector((state: any) => state.user);
    const user_id = user.id ? user.id : "";

    const [intercomList, setIntercomList] = useState([]);
    const [intercomQueryForm, setIntercomQueryForm] = useState({
        page: 1,
        size: 10,
        total: 0,
        macAddress: null
    });

    useEffect(() => {
        loadIntercom()
    }, []);

    const loadIntercom = (page = intercomQueryForm.page, size = intercomQueryForm.size, macAddress = intercomQueryForm.macAddress) => {
        let query = {
            page: page,
            size: size,
            macAddress: macAddress
        }
        intercoms(query).then(result => {
            setIntercomQueryForm((prevPagination) => ({
                ...prevPagination,
                total: result.totalElements // Update total count if available from backend
            }));
            setIntercomList(result.content as any)
        })
    }

    const showModal = (text: any, data: any) => {
        setSelectedId(data.id);
        if (text === "delete") {
            setDeleteModal(true);
        }
    };


    const handleSubmitDelete = () => {
        fetch(
            process.env.REACT_APP_API_URL +
            "intercom/delete/" +
            selectedId +
            "?id=" +
            user_id,
            {
                method: "PUT",
            }
        )
            .then(() => {
                messageApi.open({
                    type: "success",
                    content: t("intercomInfo.messageSuccessDeleteIntercom"),
                });
                setDeleteModal(false);
            })
            .catch(() => {
                messageApi.open({
                    type: "error",
                    content: t("general.somethingWentWrong"),
                });
            });
    };

    const handleCancel = () => {
        setDeleteModal(false);
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
        setIntercomQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadIntercom()
    };

    const pageChange: PaginationProps['onChange'] = (page, size) => {
        setIntercomQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadIntercom(page, size)
    };

    const onChangeMacSearch = (e: any) => {
        setMacValue(e.target.value)
        setIntercomQueryForm((prevPagination) => ({
            ...prevPagination,
            macAddress: e.target.value
        }));
        loadIntercom(intercomQueryForm.page, intercomQueryForm.size, e.target.value)
    };

    const handleResetFilter = () => {
        setMacValue("")
        setIntercomQueryForm((prevPagination) => ({
            ...prevPagination,
            macAddress: null,
        }));
        loadIntercom(intercomQueryForm.page, intercomQueryForm.size, null)
    };

    const columns: ColumnsType<IntercomData> = [
        {
            title: t("intercomInfo.macAddress"),
            dataIndex: "macAddress",
            key: "macAddress",
            //   sorter: (a, b) => a.mac_address.length - b.mac_address.length,
        },
        {
            title: t("general.action"),
            key: "action",
            render: (data, details, index) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        danger
                        className="actionButton"
                        onClick={() => showModal("delete", data)}
                    >
                        <DeleteOutlined/> {t("general.delete")}
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            {contextHolder}
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.intercomInfo")}</Row>
                <Row>
                    <Input
                        prefix={<SearchOutlined/>}
                        allowClear
                        value={macValue}
                        placeholder={t("general.search")}
                        onChange={onChangeMacSearch}
                        className="searchBar"
                    />
                    <Button
                        danger
                        type="primary"
                        onClick={handleResetFilter}
                        className="resetButton"
                    >
                        <ReloadOutlined/> {t("general.reset")}
                    </Button>
                </Row>
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={intercomList}
                    />
                </Row>
                <Row justify={"end"}>
                    <Pagination
                        showSizeChanger
                        current={intercomQueryForm.page}
                        onShowSizeChange={onShowSizeChange}
                        onChange={pageChange}
                        total={intercomQueryForm.total}
                        pageSizeOptions={[10]}
                        pageSize={10}
                    />
                </Row>
            </Space>


            {/* Delete Modal */}
            <Modal
                title={<div className="modalTitle">{t("general.delete")}</div>}
                open={deleteModal}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t("general.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmitDelete}>
                        {t("general.confirm")}
                    </Button>,
                ]}
            >
                <div>
                    {t("general.delete_one")}
                    <b>{t("general.delete_five")}</b>
                    {t("general.delete_three")}
                </div>
            </Modal>
        </div>
    );
}
