import {Route, Routes} from "react-router-dom";
import LoginPage from "./Components/LoginPage";
import "./App.css";
import {useEffect} from "react";
import MainPage from "./Components/MainPage";

function App() {
    useEffect(() => {
        if (window.location.href === "http://localhost:3000/")
            window.location.replace("http://localhost:3000/login");

        document.title = "Meta";
    });

    return (
        <div className="App">
            <Routes>
                <Route path={"/login"} element={<LoginPage/>}/>
                <Route path={"/main"} element={<MainPage/>}/>
            </Routes>
        </div>
    );
}

export default App;
