import React, {useEffect, useRef, useState} from "react";
import type {InputRef} from "antd";
import {Button, Image, Modal, Row, Space, Table} from "antd";
import {useTranslation} from "react-i18next";
import type {ColumnsType} from "antd/es/table";
import {getUnlocksDetails, unlocks} from "../../http";
import {Pageable, UnlockLogData} from "../../domain";

export default function UnlockLogPage() {
    const {t} = useTranslation();

    //search
    useRef<InputRef>(null);
    const [data, setData] = useState({} as Pageable<UnlockLogData>);
    const [detailData, setDetails] = useState({} as UnlockLogData);

    // const [unlockLogQueryForm, setUnlockLogQueryForm] = useState({
    //     page: 1,
    //     size: 5,
    //     total: 0,
    // });


    const loadData = (page = 1, pageSize = 10) => {
        unlocks({current: page - 1, pageSize: pageSize}).then(result => setData(result))
    }

    // const loadUnlockLog = (page = unlockLogQueryForm.page, size = unlockLogQueryForm.size,) => {
    //     let query = {
    //         page: page,
    //         size: size
    //     }
    //     unlocks(query).then(result => setData(result))
    // }

    useEffect(() => {
        loadData()
    }, []);


    let [detailModal, setDetailModal] = useState(false);


    const openDetails = (data: any) => {
        getUnlocksDetails(data.id).then(r => {
            setDetails(r)
            setDetailModal(true)
        })
    }

    // const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
    //     setUnlockLogQueryForm(prevState => ({
    //         ...prevState,
    //         page: page,
    //         size: size
    //     }));
    //     loadUnlockLog()
    // };
    //
    // const pageChange: PaginationProps['onChange'] = (page, size) => {
    //     setUnlockLogQueryForm(prevState => ({
    //         ...prevState,
    //         page: page,
    //         size: size
    //     }));
    //     loadUnlockLog(page, size)
    // };

    const columns: ColumnsType<UnlockLogData> = [
        {
            align: "center",
            title: t("unlockLog.gateName"),
            dataIndex: "gateName",
            key: "gateName",
        },
        {
            align: "center",
            title: t("unlockLog.name"),
            key: "residentName",
        },
        {
            align: "center",
            title: t("unlockLog.mobile"),
            key: "residentMobile",
        },
        {
            align: "center",
            title: t("unlockLog.unit"),
            key: "residentUnit",
        },
        {
            align: "center",
            title: t("unlockLog.type"),
            dataIndex: "openTypeName",
            key: "openTypeName",
        },
        {
            title: t("unlockLog.rgb"),
            dataIndex: "image1",
            key: "image1",
            align: "center",
            width: "4.5%",
            render: (data) => (
                <Image
                    className="zoomable-image"
                    preview={true}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                    }}
                    src={
                        'https://s3.eazable.sg/files/' + data
                    }
                />
            ),
        },
        {
            title: t("unlockLog.ir"),
            dataIndex: "image2",
            key: "image2",
            align: "center",
            width: "4.5%",
            render: (data) => (
                <Image
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                    }}
                    src={
                        'https://s3.eazable.sg/files/' + data
                    }
                />
            ),
        },
        {
            title: t("unlockLog.time"),
            dataIndex: "time",
            key: "time",
            align: "center",
            width: "15%",
        },
        {
            key: "action",
            title: t("unlockLog.detail"),
            render: (data) => (
                <Button disabled={data.openType !== 1} onClick={() => openDetails(data)}>Details</Button>
            ),
        },
    ];

    return (
        <div>
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.unlockLog")}</Row>
                {/*<Row>*/}
                {/*    <Input*/}
                {/*        prefix={<SearchOutlined/>}*/}
                {/*        placeholder={t("general.search")}*/}
                {/*        className="searchBar"*/}
                {/*    />*/}
                {/*    <Button*/}
                {/*        danger*/}
                {/*        type="primary"*/}
                {/*        onClick={() => console.log("reset")}*/}
                {/*        className="resetButton"*/}
                {/*    >*/}
                {/*        <ReloadOutlined/> {t("general.reset")}*/}
                {/*    </Button>*/}
                {/*</Row>*/}
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={{pageSize: data.size, onChange: loadData, total: data.totalElements}}
                        size="small"
                        columns={columns}
                        dataSource={data.content}
                    />
                </Row>
                {/*<Row justify={"end"}>*/}
                {/*    <Pagination*/}
                {/*        showSizeChanger*/}
                {/*        current={unlockLogQueryForm.page}*/}
                {/*        onShowSizeChange={onShowSizeChange}*/}
                {/*        onChange={pageChange}*/}
                {/*        total={unlockLogQueryForm.total}*/}
                {/*        pageSizeOptions={[10]}*/}
                {/*        pageSize={10}*/}
                {/*    />*/}
                {/*</Row>*/}
            </Space>

            <Modal
                width={900}
                title={<div className="modalTitle">Details</div>}
                open={detailModal}
                onCancel={() => setDetailModal(false)}
                footer={null}
            >
                <div>
                    <h1>{detailData.distance}</h1>
                    <div style={{display: "flex"}}>
                        <Image
                            className="zoomable-image"
                            preview={true}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                            }}
                            src={'https://s3.eazable.sg/files/' + detailData.image1}
                        />
                        <Image
                            className="zoomable-image"
                            preview={true}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                            }}
                            src={detailData.origin}
                        />
                    </div>
                    <Image
                        className="zoomable-image"
                        preview={true}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                        }}
                        src={'https://s3.eazable.sg/files/' + detailData.image2}
                    />

                </div>
            </Modal>
        </div>
    );
}
