import React from "react";
import {useTranslation} from "react-i18next";
import type {UploadProps} from "antd";
import {Button, Form, Input, message, Radio, Row, Space, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import type {FormInstance} from "antd/es/form";

const {TextArea} = Input;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 24},
};

const tailLayout = {
    wrapperCol: {offset: 11, span: 10},
};

const props: UploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
        authorization: "authorization-text",
    },
    onChange(info) {
        if (info.file.status !== "uploading") {
            console.log("file", info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

export default function FeedbackPage() {
    const [form] = Form.useForm();
    const formRef = React.useRef<FormInstance>(null);

    //multilanguage
    const {t, i18n} = useTranslation();

    const onFinish = (values: any) => {
        console.log("values", values);
    };

    return (
        <div>
            <Space
                direction="vertical"
                size="middle"
                style={{padding: "20px", width: "45vw", textAlign: "left"}}
            >
                <Row className="tabTitle">{t("menu.feedback")}</Row>
                <Form
                    {...layout}
                    ref={formRef}
                    name="feedback"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={t("feedback.ticketType")}
                        name="ticket"
                        rules={[
                            {required: true, message: "Please select the Ticket Type!"},
                        ]}
                    >
                        <Radio.Group>
                            <Radio.Button value="featureRequest">
                                {t("feedback.featureRequest")}
                            </Radio.Button>
                            <Radio.Button value="comment">
                                {t("feedback.comment")}
                            </Radio.Button>
                            <Radio.Button value="bug">{t("feedback.bug")}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("feedback.priority")}
                        name="priority"
                        rules={[{required: true, message: "Please select the Priority!"}]}
                    >
                        <Radio.Group>
                            <Radio.Button value="lowImportance">
                                {t("feedback.lowImportance")}
                            </Radio.Button>
                            <Radio.Button value="goodToDo">
                                {t("feedback.goodToDo")}
                            </Radio.Button>
                            <Radio.Button value="urgent">{t("feedback.urgent")}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("feedback.screenshotIssue")}
                        name="screenshotIssue"
                    >
                        <Upload {...props} maxCount={3} multiple>
                            <Button icon={<UploadOutlined/>}>{t("feedback.upload")}</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label={t("feedback.description")}
                        name="Description"
                        rules={[
                            {required: true, message: "Please enter your Description!"},
                        ]}
                    >
                        <TextArea
                            rows={10}
                            placeholder={t("feedback.details")}
                            style={{resize: "none"}}
                        />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            {t("feedback.submit")}
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </div>
    );
}
