import {useEffect, useState} from "react";
import {Button, Input, Pagination, PaginationProps, Row, Select, Space, Table,} from "antd";
import {PhoneOutlined, ReloadOutlined, UserOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {ColumnsType} from "antd/es/table";
import {UserData} from "../../domain/user";
import {users} from "../../http";

export default function UserInfoPage() {
    //multilanguage
    const {t} = useTranslation();

    //search
    const [nameValue, setNameValue] = useState("");
    const [phoneValue, setPhoneValue] = useState("");
    const [statusValue, setStatusValue] = useState("false");

    const project = useSelector((state: any) => state.project);

    const [userList, setUserList] = useState([] as Array<UserData>);
    const [userQueryForm, setUserQueryForm] = useState({
        page: 1,
        size: 10,
        total: 0,
        status: false,
        name: null,
        mobile: null
    });

    useEffect(() => {
        loadUsers()
    }, [project]);

    const loadUsers = (page = userQueryForm.page,
                       size = userQueryForm.size,
                       status = userQueryForm.status,
                       name = userQueryForm.name,
                       mobile = userQueryForm.mobile) => {
        let query = {
            page: page,
            size: size,
            status: status,
            name: name,
            mobile: mobile
        }
        users(query).then(result => {
            setUserQueryForm((prevPagination) => ({
                ...prevPagination,
                total: result.totalElements // Update total count if available from backend
            }));
            setUserList(result.content as any);
        })
    };


    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (page, size) => {
        setUserQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadUsers()
    };

    const pageChange: PaginationProps['onChange'] = (page, size) => {
        setUserQueryForm(prevState => ({
            ...prevState,
            page: page,
            size: size
        }));
        loadUsers(page, size)
    };

    const onChangeNameSearch = (e: any) => {
        setNameValue(e.target.value)
        setUserQueryForm((prevPagination) => ({
            ...prevPagination,
            name: e.target.value
        }));
        loadUsers(userQueryForm.page, userQueryForm.size, userQueryForm.status, e.target.value, userQueryForm.mobile)
    };

    const onChangePhoneSearch = (e: any) => {
        setPhoneValue(e.target.value)
        setUserQueryForm((prevPagination) => ({
            ...prevPagination,
            mobile: e.target.value
        }));
        loadUsers(userQueryForm.page, userQueryForm.size, userQueryForm.status, userQueryForm.name, e.target.value)
    };

    const onChangeStatus = (value: string) => {
        let status = value === "true";
        setStatusValue(value)
        setUserQueryForm((prevPagination) => ({
            ...prevPagination,
            status: status
        }));
        loadUsers(userQueryForm.page, userQueryForm.size, status)
    };

    const handleResetFilter = () => {
        setNameValue("")
        setPhoneValue("")
        setStatusValue("false")
        setUserQueryForm((prevPagination) => ({
            ...prevPagination,
            status: false,
            name: null,
            mobile: null
        }));
        loadUsers(userQueryForm.page, userQueryForm.size, false, null, null)
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const columns: ColumnsType<UserData> = [
        {
            title: t("userInfo.name"),
            dataIndex: "name",
            key: "name",
            width: "10%",
            //   sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: t("userInfo.phone"),
            dataIndex: "mobile",
            key: "mobile",
            width: "10%",
        },
    ];


    return (
        <div>
            <Space direction="vertical" size="middle" style={{padding: "20px"}}>
                <Row className="tabTitle">{t("menu.userInfo")}</Row>
                <Row>
                    <Input
                        prefix={<UserOutlined/>}
                        allowClear
                        value={nameValue}
                        placeholder={t("userInfo.searchNamePlaceholder")}
                        onChange={onChangeNameSearch}
                        className="searchBar"
                    />
                    <Input
                        prefix={<PhoneOutlined/>}
                        allowClear
                        value={phoneValue}
                        placeholder={t("userInfo.searchPhonePlaceholder")}
                        onChange={onChangePhoneSearch}
                        className="searchBar"
                        type="number"
                    />
                    <Select
                        showSearch
                        placeholder="Status"
                        optionFilterProp="children"
                        onChange={onChangeStatus}
                        filterOption={filterOption}
                        className="statusBar"
                        size={"large"}
                        defaultValue="false"
                        value={statusValue}
                        options={[
                            {
                                value: "false",
                                label: 'Active',
                            },
                            {
                                value: "true",
                                label: 'InActive',
                            }
                        ]}
                    />
                    <Button
                        danger
                        type="primary"
                        onClick={handleResetFilter}
                        className="resetButton"
                    >
                        <ReloadOutlined/> {t("general.reset")}
                    </Button>
                </Row>
                <Row>
                    <Table
                        style={{width: "85vw"}}
                        bordered
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={userList}
                    />
                </Row>
                <Row justify={"end"}>
                    <Pagination
                        showSizeChanger
                        current={userQueryForm.page}
                        onShowSizeChange={onShowSizeChange}
                        onChange={pageChange}
                        total={userQueryForm.total}
                        pageSizeOptions={[10]}
                        pageSize={10}
                    />
                </Row>
            </Space>
        </div>
    );
}
