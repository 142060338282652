import {httpClient} from "./client";
import {Pageable, ProjectData, UnlockLogData} from "../domain";
import {AccountData} from "../domain/account";
import {GateData} from "../domain/gate";
import {ZoneData} from "../domain/zone";
import {CardData} from "../domain/card";
import {UserData} from "../domain/user";


interface Account {
    id: number;
    name: string
    phone: string
    language: string
    permission: string
    project: string
}

export const loginApi = (values: any) => httpClient.post<Account>("/account/login", values)
export const logoutApi = () => httpClient.post<Account>("/account/logout")
export const intercoms = (query: any) => httpClient.get<any>("/intercom", query)
export const switchProject = (id: Number) => httpClient.post<any>(`/project/switch/${id}`)
export const pageProjects = (query: any) => httpClient.get<Pageable<ProjectData>>(`/project/page`, query)
export const listProjects = () => httpClient.get<ProjectData>(`/project/list`)
export const permissionProjects = () => httpClient.get<any>(`/project/permissionProject`)
export const accounts = (query: any) => httpClient.get<Pageable<AccountData>>("/account/getAll", query)
export const pageGates = (query: any) => httpClient.get<Pageable<GateData>>(`/gate/page`, query)
export const listGates = () => httpClient.get<any>(`/gate/list`)
export const zones = (query: any) => httpClient.get<Pageable<ZoneData>>(`/zone`, query)
export const secrets = (query: any) => httpClient.get<Pageable<CardData>>(`/card`, query)
export const users = (query: any) => httpClient.get<Pageable<UserData>>(`/user`, query)
export const unlocks = (params: any) => httpClient.get<Pageable<UnlockLogData>>("/unlockLog", params)
export const getUnlocksDetails = (id: Number) => httpClient.get<UnlockLogData>(`/unlockLog/${id}`)
