import {createSlice} from "@reduxjs/toolkit";

const initialState = {};

export const projectSlice = createSlice({
    name: "project",
    initialState: initialState,
    reducers: {
        changeProject: (state, action) => {
            Reflect.set(state, "id", action.payload.id);
        },
        clear: () => initialState,
    },
});

export const {changeProject, clear} = projectSlice.actions;
export default projectSlice.reducer;
